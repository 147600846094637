import { BaseDto } from '@/shared/dtos/base-dto';
import { alimento_cantidad_ficha_platoDto } from './alimento_cantidad_ficha_platoDto';

export class plato_ficha extends BaseDto {

    public nueva_platos_id_estructura !: string;
    public nueva_platos_id !: number;
    public vet100 !: number;
    public nueva_platos_azucares_anadidos !: string;
    public porcentaje_gr !: number;
    public porcentaje_glucidos !: number;
    public porcentaje_protein !: number;
    public vet_racion !: number;
    public id_talla_plato !: number;
    public cabezera_platos_id !: number;
    public cabezera_platos_nombre !: string;
    public duracion !: number;
    public comensales !: number;
    public receta !: string;
    public cabezera_platos_observaciones !: string;
    
    public apropiado_desayuno !: boolean;
    public apropiado_tentempie !: boolean;
    public apropiado_comida !: boolean;
    public apropiado_merienda !: boolean;
    public apropiado_cena !: boolean;
    public apropiado_recena !: boolean;
    public apropiado_postre !: boolean;
    
    public solo_deportist !: boolean;
    public solo_veganos !: boolean;
    public apto_veganos !: boolean;
    public solo_celiacos !: boolean;
    public solo_paleo !: boolean;
    public apto_paleo !: boolean;
    public solo_intolerantes_lactosa !: boolean;
    public solo_textura !: boolean;
    public apto_textura !: boolean;
    public apto_gtritis !: boolean;
    public apto_menopausia !: boolean;
    public apto_hipertension !: boolean;
    public apto_obesidad !: boolean;
    public apto_hipertrigliceridemia !: boolean;
    public apto_hipertrigliceridemia_grave !: boolean;
    public plato_unico !: boolean;
    
    public alergeno_lacteos !: boolean;
    public alergeno_huevos !: boolean;
    public alergeno_soja !: boolean;
    public alergeno_apio !: boolean;
    public alergeno_altramuz !: boolean;
    public alergeno_pescado !: boolean;
    public alergeno_sulfuro !: boolean;
    public alergeno_nueces !: boolean;
    public alergeno_mostaza !: boolean;
    public alergeno_moluscos !: boolean;
    public alergeno_sesamo !: boolean;
    public alergeno_cacahuetes !: boolean;
    public alergeno_gluten !: boolean;
    
    public id_tipo_plato !: number;
    public id_dificultad_plato !: number;
    public id_nutricionista !: number;
    public dificultad_plato_id !: number;
    public dificultad_plato_nombre !: string;
    public dificultad_plato_nombre_corto !: string;
    public nivel !: string;
    public talla_plato_id !: number;
    public talla !: string;
    public tipo_plato_id !: number;
    public nombre_tipo_plato !: string;
    public cabezera_platos_sistema !: boolean;
    public nombre !: string;
    public porcion_comestible !: string;
    public azucares_anadidos !: string;
    public nombre_grupo_ali !: string;
    public observaciones !: string;
    public nombre_temporada !: string;


    public energia !: string;
    public etanol !: string;
    
    public glucidos_totales !: string;
    public polisacaridos !: string;
    public azucares !: string;
    public fibra !: string;

    public grasas_totales !: string;
    public agmi !: string;
    public agpi !: string;
    public ags !: string;
    public colesterol !: string;

    public proteinas_totales !: string;
    public proteinas_animales !: string;
    public proteinas_vegetales !: string;

    public iodo !: string;
    public sodio !: string;
    public potasio !: string;
    public calcio !: string;
    public magnesio !: string;
    public fosforo !: string;
    public hierro !: string;
    public zinc !: string;
    public selenio !: string;

    public vitamina_b1 !: string;
    public vitamina_b2 !: string;
    public vitamina_b6 !: string;
    public vitamina_b12 !: string;
    public acido_folico !: string;
    public niacina !: string;
    public vitamina_c !: string;
    public vitamina_a !: string;
    public vitamina_d !: string;
    public vitamina_e !: string;

    public sistema !: boolean;
    public id_estructura !: number;
    public id_grupo_alimento !: number;
    public id_temporada !: number;

    public alimentos!:alimento_cantidad_ficha_platoDto[];

    public display_text_combo!:string;
} 
