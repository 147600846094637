import { datatypes } from './datatypes';
import { types } from './types';
import { ClassDxLookup } from './ClassDxLookup';

export class ClassColumnDataTable {
    public datafield!: string;
    public caption!: string;
    public datatype!: datatypes;
    public format: types;
    public visible!: boolean;
    public Required: boolean = false;
    public maxLength !: number;
    public alignment: datatypes = datatypes.left;
    public Lookup: ClassDxLookup = new ClassDxLookup();
    public formatString: string = '';
    public cell_template: string = "";
    public groupindex!:number;
    public width!:number;

    constructor(datafield: string, caption: string, datatype: datatypes, visible: boolean, maxLength: number) {
        this.datafield = datafield;
        this.caption = caption;
        this.datatype = datatype;
        this.visible = visible;
        this.format = "";
        this.maxLength = maxLength;
    }
    public isRequired(Required: boolean) {
        this.Required = Required;
        return this;
    }
    public ChangeAlignment(alignment: datatypes) {
        this.alignment = alignment;
        return this;
    }
    public CreateDxLookup(DataSource: any, DisplayExpr: string, ValueExpr: string = "Id") {
        this.Lookup.DataSource = DataSource;
        this.Lookup.DisplayExpr = DisplayExpr;
        this.Lookup.ValueExpr = ValueExpr;
        return this;
    }
    public CreateFromatString(Format: string) {
        this.formatString = Format;
        return this;
    }
    public CreateTemplate(name_template: string) {
        this.cell_template = name_template;
        return this;
    }
    public ChangeWidth(width: number) {
        this.width = width;
        return this;
    }

}