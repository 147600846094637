import { store } from '@/store/store';
import { suplemento } from '@/shared/dtos/visualizacion_dieta/suplemento';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { plato_ficha } from '@/shared/dtos/visualizacion_dieta/plato_ficha';
import { FilterDataDto } from '@/shared/dtos/visualizacion_dieta/FilterDataDto';
import { suplmentos_fichaDto } from '@/shared/dtos/visualizacion_dieta/base_datos_alimentos/suplmentos_fichaDto';

@Module({
    namespaced: true,
    name: 'suplementoModule',
    store,
    dynamic: true
})
class suplementoModule extends VuexModule {
    public suplementos: suplemento[] = [];
    public suplemento: suplemento = new suplemento();

    public suplemento_ficha: plato_ficha = new plato_ficha();

    public suplemento_ficha_alta: suplmentos_fichaDto = new suplmentos_fichaDto();

    public all_suplementos: FilterDataDto[] = [];

    @Action({ commit: 'onGetsuplementos' })
    public async getsuplementos() {
        return await ssmHttpService.get(API.suplemento);
    }

    @Action({ commit: 'onGetsuplemento' })
    public async getsuplemento(id: any) {
        return await ssmHttpService.get(API.suplemento + '/' + id);
    }

    @Action
    public async guardarsuplemento(suplemento: suplemento) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.suplemento, suplemento.toJson());
    }

    @Action
    public async modificarsuplemento(suplemento: suplemento) {
        await ssmHttpService.put(API.suplemento, suplemento);
    }

    @Action
    public async guardarsuplemento_ficha(suplemento: suplmentos_fichaDto) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.suplemento + '/ficha/', suplemento.toJson());
    }

    @Action
    public async modificarsuplemento_ficha(suplemento: suplmentos_fichaDto) {
        await ssmHttpService.put(API.suplemento + '/ficha/', suplemento);
    }

    
    @Action
    public async eliminar_ficha_suplemento(id_suplemento: number) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.delete(API.suplemento + '/ficha/' + id_suplemento, null, false);
    }


    @Action
    public async eliminarsuplemento(suplemento: suplemento) {
        await ssmHttpService.delete(API.suplemento + '/' + suplemento.id, null, false);
    }

    @Action({ commit: 'onGetall_suplementos' })
    public async getall_suplementos({ id_dieta, id_paciente }: any) {
        return await ssmHttpService.get(API.suplemento + '/all/' + id_dieta + '/' + id_paciente);
    }

    @Action({ commit: 'onGetall_suplementos' })
    public async getall_suplementos_lista() {
        return await ssmHttpService.get(API.suplemento + '/lista/');
    }

    @Action
    public async search_suplemento({ id_dieta, id_paciente }: any) {
        if (this.all_suplementos.length === 0) {
            await this.getall_suplementos({ id_dieta: id_dieta, id_paciente: id_paciente });
        }
        return this.all_suplementos;
    }


    @Action({ commit: 'onGetsuplementoFicha' })
    public async getsuplementoficha(id_estructura: number) {
        return await ssmHttpService.get(API.suplemento + '/ficha/' + id_estructura);
    }

    @Mutation
    public onGetsuplementos(res: suplemento[]) {
        this.suplementos = res ? res.map((x) => new suplemento(x)) : []
    }

    @Mutation
    public onGetsuplemento(res: suplemento) {
        this.suplemento = new suplemento(res);
    }

    @Mutation
    public onGetsuplementoFicha(res: plato_ficha) {
        this.suplemento_ficha = res;// ? res.map((x) => new plato_ficha(x)) : []
        this.suplemento_ficha_alta = new suplmentos_fichaDto(res);
    }

    @Mutation
    public onGetsuplementoFichaReset(res: suplmentos_fichaDto) {
        this.suplemento_ficha_alta = res;
    }

    @Mutation
    public onGetall_suplementos(res: FilterDataDto[]) {
        this.all_suplementos = res ? res.map((x) => new FilterDataDto(x)) : []
    }


}
export default getModule(suplementoModule);