import { BaseDto } from '@/shared/dtos/base-dto';

export class estructura_basicaDto extends BaseDto {
    public id_estructura !: number;
    public energia !: number;
    public etanol !: number;
    public glucidos_totales !: number;
    public polisacaridos !: number;
    public azucares !: number;
    public fibra !: number;
    public grasas_totales !: number;
    public agmi !: number;
    public agpi !: number;
    public ags !: number;
    public colesterol !: number;
    public proteinas_totales !: number;
    public proteinas_animales !: number;
    public proteinas_vegetales !: number;
    public iodo !: number;
    public sodio !: number;
    public potasio !: number;
    public calcio !: number;
    public magnesio !: number;
    public fosforo !: number;
    public hierro !: number;
    public zinc !: number;
    public selenio !: number;
    public vitamina_b1 !: number;
    public vitamina_b2 !: number;
    public vitamina_b6 !: number;
    public vitamina_b12 !: number;
    public acido_folico !: number;
    public niacina !: number;
    public vitamina_c !: number;
    public vitamina_a !: number;
    public vitamina_d !: number;
    public vitamina_e !: number;
 } 
