import { BaseDto } from '@/shared/dtos/base-dto';

export class suplemento extends BaseDto {
    public nombre !: string;
    public id_estructura !: number;
    public id_talla_plato !: number;
    public porcentaje_gr !: number;
    public porcentaje_glucidos !: number;
    public porcentaje_protein !: number;
    public apropiado_desayuno !: boolean;
    public apropiado_tentempie !: boolean;
    public apropiado_comida !: boolean;
    public apropiado_merienda !: boolean;
    public apropiado_cena !: boolean;
    public apropiado_recena !: boolean;
    public apropiado_postre !: boolean;

 } 
